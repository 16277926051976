import React from 'react';
import Layout from '../../../../modules/layout';
import EventsFilterView from '../../../../components/cms-modules/eventTeaser/components/eventsFilterView';
import { graphql, useStaticQuery } from 'gatsby';
import { ArrayParam, StringParam, useQueryParams } from 'use-query-params';
import { useLocale } from '../../../../context/locale.context';

const EventsOverview = () => {
  const { locale } = useLocale();

  const [query, _] = useQueryParams({
    eventTypeId: ArrayParam,
    yearId: ArrayParam,
    regionId: ArrayParam,
    sort: StringParam,
  });

  const { allContentfulSiteSettings } = useStaticQuery(
    graphql`
      query {
        allContentfulSiteSettings {
          nodes {
            node_locale
            contactEmail
          }
        }
      }
    `
  );

  const contactEmail = allContentfulSiteSettings.nodes.filter(
    (n) => n.node_locale === locale
  )[0].contactEmail;

  const queryFilters = {
    eventType: query.eventTypeId ? query.eventTypeId.map(Number) : [],
    region: query.regionId ? query.regionId.map(String) : [],
    time: query.yearId ? query.yearId.map(Number) : [],
    sort: query.sort ? query.sort : 'asc',
  };

  return (
    <Layout
      title={'title.events'}
      description={'desc.events'}
      slug={'if-magazine/events/overview'}
    >
      <EventsFilterView
        contactEmail={contactEmail}
        queryFilters={queryFilters}
      />
    </Layout>
  );
};

export default EventsOverview;
