// extracted by mini-css-extract-plugin
export var button = "styles-module--button--10601";
export var cappedWidth = "styles-module--cappedWidth--59034";
export var category = "styles-module--category--98ae6";
export var country = "styles-module--country--99005";
export var date = "styles-module--date--d26fd";
export var dates = "styles-module--dates--4341e";
export var description = "styles-module--description--a0e54";
export var image = "styles-module--image--72c4e";
export var location = "styles-module--location--101e5";
export var teaserItem = "styles-module--teaserItem--0360b";
export var title = "styles-module--title--4e1e8";