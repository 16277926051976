import React, { useContext } from 'react';
import { MicrocopyContext } from '../../../../../context/microcopy.context';
import { getCopy } from '../../../../../helpers/copy';
import { useEventFilters } from '../../../../../hooks/useEventFilters';
import Container from '../../../../../modules/container';
import Headline from '../../../../ui/headline';
import Link from '../../../../ui/link';
import ResultsList from '../../../../ui/resultsList';
import Text from '../../../../ui/text';
import EventTeaserItem from '../eventTeaserItem';
import * as styles from './styles.module.scss';

const EventsFilterView = ({ contactEmail, queryFilters }) => {
  const microcopy = useContext(MicrocopyContext);
  return (
    <>
      <div className={styles.headlineOuterContainer}>
        <Container className={styles.headlineContainer}>
          <Headline
            text={getCopy('label.eventsOverviewHeadline', microcopy)}
            level="h1"
            type="h1"
          />
          <Text as="p" type="p1" className={styles.subheadline}>
            {getCopy('label.eventsOverviewSubheadline', microcopy)}
          </Text>
          <Text as="p" type="p1" className={styles.contactText}>
            {getCopy('label.eventsOverviewContactText', microcopy)}
            <Link
              link={{ externalLink: contactEmail }}
              mailto
              className={styles.contactTextLink}
            >
              <Text as="span" type="p1">
                {getCopy('label.eventsOverviewContactLinkText', microcopy)}
              </Text>
            </Link>
          </Text>
        </Container>
      </div>
      <ResultsList
        queryFilters={queryFilters}
        filtersHook={useEventFilters}
        itemRenderer={EventTeaserItem}
        resultsContainerClassName={styles.container}
        countLabelKey="Events"
        borderInHeader
        type="event"
      />
    </>
  );
};
export default EventsFilterView;
