import React from 'react';
import pt from 'prop-types';
import Image from '../../../../ui/image';
import Text from '../../../../ui/text';
import * as styles from './styles.module.scss';
import { combine } from '../../../../../helpers/styles';
import Link from '../../../../ui/link';
import { getFormattedDate } from '../../../../../helpers/getFormattedDate';
import { useLocale } from '../../../../../context/locale.context';
import { DYNAMIC_PAGE_TYPES } from '../../../../../utils/constants';
import { getDynamicPageLink } from '../../../../../helpers/getDynamicPageLink';

const EventTeaserItem = ({
  id,
  startDate,
  endDate,
  category,
  title,
  description,
  location,
  image,
  cappedWidth,
  country,
}) => {
  const { locale } = useLocale();

  const getDate = (date) => {
    const [weekDay, day, month, year] = getFormattedDate(
      date,
      'eeeeee dd MMM yyyy',
      locale
    ).split(' ');
    return (
      <div className={styles.date}>
        <Text as="span" type="metanavi">
          {weekDay}
        </Text>
        <Text
          as="span"
          type="p1"
          variant="semibold"
        >{` ${day} ${month} `}</Text>
        <Text as="span" type="metanavi">
          {year}
        </Text>
      </div>
    );
  };

  return (
    <Link
      className={combine(styles.teaserItem, cappedWidth && styles.cappedWidth)}
      link={{
        linkToPage: getDynamicPageLink(
          DYNAMIC_PAGE_TYPES.EVENT_DETAIL,
          title,
          id
        ),
      }}
      useGatsbyLink={false}
    >
      <div as="span" className={styles.dates}>
        {getDate(startDate)}
        {startDate !== endDate && (
          <Text as="span" type="p1">
            -
          </Text>
        )}
        {startDate !== endDate && getDate(endDate)}
      </div>
      <Text
        as="span"
        type="infotext"
        variant="semibold"
        className={styles.category}
      >
        {category.toUpperCase()}
      </Text>
      <Text
        type="h2"
        variant="semibold"
        style="editorial"
        className={styles.title}
      >
        {title}
      </Text>
      <Image image={{ url: image }} className={styles.image}></Image>
      <Text type="metanavi" className={styles.description}>
        {description}
      </Text>
      <Text type="infotext" variant="semibold" className={styles.location}>
        {location}
        {country && (
          <Text
            as="span"
            type="infotext"
            variant="semibold"
            className={styles.country}
          >
            {country}
          </Text>
        )}
      </Text>
    </Link>
  );
};

EventTeaserItem.propTypes = {
  id: pt.number,
  startDate: pt.string,
  endDate: pt.string,
  category: pt.string,
  title: pt.string,
  description: pt.string,
  location: pt.string,
  image: pt.string,
  cappedWidth: pt.bool,
  country: pt.string,
};

EventTeaserItem.defaultProps = {
  id: 0,
  startDate: '',
  endDate: '',
  category: '',
  title: '',
  description: '',
  location: '',
  image: '',
  cappedWidth: false,
  country: '',
};

export default EventTeaserItem;
